export const API_URL = 'https://toppay-api.stage.ro.sw360services.com/api'
// export const API_URL = 'https://api.staging.toppay.site/api'
export const DATETIME_FORMAT = 'DD MMMM YYYY, h:mm a'
export const DATE_FORMAT = 'MMMM, YYYY'
export const CALENDAR_DATE_FORMAT = 'MM/DD/YYYY'
export const DEBOUNCE_MS = 400
export const clientConfig = {
  client_id: 'virtual_terminal',
  client_secret: 'zMFKMgcGVF5-G%^cxk2C',
  grant_type: 'password'
}
export const clientConfigRefresh = {
  client_id: 'virtual_terminal',
  client_secret: 'zMFKMgcGVF5-G%^cxk2C',
  grant_type: 'refresh_token'
}
