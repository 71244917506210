import React from 'react'
import {
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
  INVALID_TOKENS
} from './AuthActions'
import moment from 'moment-timezone'
import { reducerPersistor, statePersister } from '../../helpers/contextPersistor'

const STORAGE_KEY = 'auth'
const initialState = {
  refreshing: null,
  loading: false,
  access_token: undefined,
  refresh_token: undefined,
  expires_in: undefined,
  token_type: '',
  loggedIn: false,
  user: {},
  invalidTokens: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case REFRESH_TOKEN:
      return { ...state, refreshing: action.payload }
    case LOGIN_USER:
      return { ...state, loading: true, error: '' }
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshing: null,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        expires_in: moment().valueOf() + action.payload.expires_in * 1000,
        // expires_in: 100000 + moment().valueOf(),
        token_type: action.payload.token_type
      }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        refreshing: null,
        loading: false,
        error: '',
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        // expires_in: 100000 + moment().valueOf(),
        expires_in: moment().valueOf() + (action.payload.expires_in * 1000),
        token_type: action.payload.token_type,
        loggedIn: true,
        invalidTokens: false
      }
    case LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false
      }
    case REFRESH_TOKEN_FAIL:
      return { ...state, refreshing: false }
    case LOGOUT_SUCCESS:
      return {
        ...initialState
      }
    case INVALID_TOKENS:
      return {
        ...initialState,
        invalidTokens: true
      }
    default:
      return state
  }
}
const AuthContext = React.createContext()

function AuthContextProvider (props) {
  const [state, dispatch] = React.useReducer(reducerPersistor(STORAGE_KEY, reducer), statePersister(STORAGE_KEY, initialState))
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  )
}

const AuthContextConsumer = AuthContext.Consumer

export { AuthContext, AuthContextProvider, AuthContextConsumer }
