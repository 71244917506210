import axios from 'axios'
import moment from 'moment-timezone'
import { clientConfig, clientConfigRefresh } from '../../api/config'
import { makeAuthRequest } from '../../api/RequestHelpers'
import { checkForDefaultError } from '../../helpers/errorFunctions'
import _ from 'lodash'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const INVALID_TOKENS = 'INVALID_TOKENS'
export const login = async ({ authContext, values, history }) => {
  let response
  try {
    response = await axios({
      url: '/oauth2/token',
      method: 'post',
      data: {
        ...clientConfig,
        ...values
      }
    }
    )
    authContext.dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: response.data
    })
    localStorage.setItem('token', JSON.stringify({
      ...response.data,
      expires_in: moment().valueOf() + response.data.expires_in * 1000
    }))
    history.push('/home')
    return response.data
  } catch (err) {
    if (checkForDefaultError(err)) {
      throw checkForDefaultError(err)
    } else {
      throw Error('Unexpected error')
    }
  }
}

export const logout = async ({ authContext, history }) => {
  let response
  try {
    response = await makeAuthRequest({
      url: '/oauth2/logout',
      method: 'post',
      data: {
        client_id: clientConfig.client_id,
        client_secret: clientConfig.client_secret
      }
    })({ authContext, history })
    authContext.dispatch({
      type: LOGOUT_SUCCESS,
      payload: response.data
    })
    history.push('/auth/login')
  } catch (e) {
    authContext.dispatch({
      type: LOGOUT_SUCCESS
    })
  }
}

export const refreshToken = async authContext => {
  console.log('gggTokenExpired refresh token')
  localStorage.setItem('tokenExpired', false)
  const refreshing = axios({
    url: '/oauth2/token',
    method: 'post',
    data: {
      ...clientConfigRefresh,
      refresh_token: authContext.state.refresh_token
    }
  })
  authContext.dispatch({
    type: REFRESH_TOKEN,
    payload: refreshing
  })

  try {
    const response = await refreshing
    authContext.dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      payload: response.data
    })
    localStorage.setItem('token', JSON.stringify({
      ...response.data,
      expires_in: moment().valueOf() + response.data.expires_in
      // expires_in: response.data.expires_in * 10000
    }))
    return response.data
  } catch (e) {
    if (_.get(e, 'response.data.error_type') === 'expired_token_error' ||
      _.get(e, 'response.data.error_type') === 'authentication_error'
    ) {
      localStorage.setItem('tokenExpired', true)
    }
    authContext.dispatch({
      type: REFRESH_TOKEN_FAIL
    })
    throw e
  }
}
